import axios from "axios";
import { useCallback, useMemo } from "react";

export const useBlogApi = () => {
  const client = useMemo(() => axios.create({}), []);

  const getPosts = useCallback(async (): Promise<BlogPost[]> => {
    const res = await client
      .get<PostsResponse>(
        "https://blog.acquinox.capital/api/posts?populate=author,coverImage&pagination[page]=1&pagination[pageSize]=3&sort[0]=publishedAt:desc",
      )
      .then((res) => res.data);
    const parsed: BlogPost[] = res.data.map((item) => {
      const { title, coverImage, publishedAt } = item.attributes;
      let url = `https://acquinox.capital${coverImage.data.attributes.url}`;
      return {
        id: item.id,
        imageUrl: url,
        publishedAt,
        title,
        postUrl: `/blog/${item.attributes.slug}`,
      };
    });
    return parsed;
  }, [client]);

  return {
    getPosts,
  };
};

export type BlogPost = {
  id: number;
  title: string;
  publishedAt: string;
  imageUrl: string;
  postUrl: string;
};

type PostsResponse = {
  data: {
    id: number;
    attributes: {
      title: string;
      publishedAt: string;
      slug: string;
      coverImage: {
        id: number;
        data: {
          attributes: {
            id: number;
            formats: {
              thumbnail?: {
                url: string;
              };
            };
            url: string;
          };
        };
      };
    };
  }[];
};
